@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/mixins';

// 2. Vendors
@import
  "vendors/jquery.arcticmodal-0.3",
  "vendors/slick",
  "vendors/lightbox";

// 3. Base stuff
@import
  'base/typography',
  'base/fonts',
  'base/base';

// 4. Components
@import
 'components/main';

// 5. Layout-related sections
@import
  'layout/grid';


