@mixin transition($args...) {
  transition: $args;
}

/* media */

@mixin sm-max {
  @media (min-width: #{$grid-sm}) {
    @content;
  }
}

@mixin md-max {
  @media (min-width: #{$grid-md}) {
    @content;
  }
}

@mixin lg-max {
  @media (min-width: #{$grid-lg}) {
    @content;
  }
}

@mixin xl-max {
  @media (min-width: #{$grid-xl}) {
    @content;
  }
}
