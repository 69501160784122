/* Gilroy-900 */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 900;
  src: url('/build/fonts/Gilroy/Gilroy-Heavy.ttf');
}
/* Gilroy-600 */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src: url('/build/fonts/Gilroy/Gilroy-SemiBold.ttf');
}