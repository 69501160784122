$font-main: 'Gilroy', Arial, Helvetica, sans-serif;

$size-main: 45px;
$size-header: 25px;

$color-main: #282828;
$color-header: #040404;
$color-link: #040404;
$color-white: #FFF;
$color-grey: #EFEFEF;
$color-blue: #1A47BB;
$color-blue-opacity: rgba(26, 71, 187, 0.5);
$color-red: #DA0000;
$color-black: #000;

$bgc-main: #1A47BB;
$bgc-main-dark: #07277A;
$bgc-main-white: #E5E5E5;
$bgc-transparent-grey: rgba(4, 4, 4, 0.7);
$bgc-transparent-blue: rgba(26, 71, 187, 0.1);

$box-shadow-main: 0px 4px 20px rgba(0, 0, 0, 0.1);

// breakpoints
$grid-sm: 576px;
$grid-md: 768px;
$grid-lg: 992px;
$grid-xl: 1200px;

// Ширина контейнера
$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;

$container-secondary: 1080px;

