.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @include sm-max {
    width: $container-sm;
  }
  @include md-max {
    width: $container-md;
  }
  @include lg-max {
    width: $container-lg;
  }
  @include xl-max {
    width: $container-xl;
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.container-secondary {
  max-width: $container-secondary;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  &_align-center {
    align-items: center;
  }

  &_align-end {
    align-items: flex-end;
  }

  &_justify-between {
    justify-content: space-between;
  }
}

.col-auto, .col-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xl-1, .col-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xl-2, .col-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xl-3, .col-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xl-4, .col-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xl-5, .col-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6, .col-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xl-7, .col-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xl-8, .col-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xl-9, .col-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xl-10, .col-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xl-11, .col-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xl-12 {
  position: relative;
  min-height: 1px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-offset-12 {
  margin-left: 100%;
}

.col-offset-11 {
  margin-left: 91.66666667%;
}

.col-offset-10 {
  margin-left: 83.33333333%;
}

.col-offset-9 {
  margin-left: 75%;
}

.col-offset-8 {
  margin-left: 66.66666667%;
}

.col-offset-7 {
  margin-left: 58.33333333%;
}

.col-offset-6 {
  margin-left: 50%;
}

.col-offset-5 {
  margin-left: 41.66666667%;
}

.col-offset-4 {
  margin-left: 33.33333333%;
}

.col-offset-3 {
  margin-left: 25%;
}

.col-offset-2 {
  margin-left: 16.66666667%;
}

.col-offset-1 {
  margin-left: 8.33333333%;
}

.col-offset-0 {
  margin-left: 0;
}

@include sm-max {

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }
}

@include md-max {

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
}

@include lg-max {
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }
}

@include xl-max {
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }

  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }
}

.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}

.order-sm-0 {
  @include sm-max {
    order: 0;
  }
}
.order-sm-1 {
  @include sm-max {
    order: 1;
  }
}
.order-sm-2 {
  @include sm-max {
    order: 2;
  }
}
.order-sm-3 {
  @include sm-max {
    order: 3;
  }
}
.order-sm-4 {
  @include sm-max {
    order: 4;
  }
}
.order-sm-5 {
  @include sm-max {
    order: 5;
  }
}
.order-sm-6 {
  @include sm-max {
    order: 6;
  }
}
.order-sm-7 {
  @include sm-max {
    order: 7;
  }
}
.order-sm-8 {
  @include sm-max {
    order: 8;
  }
}
.order-sm-9 {
  @include sm-max {
    order: 9;
  }
}
.order-sm-10 {
  @include sm-max {
    order: 10;
  }
}
.order-sm-11 {
  @include sm-max {
    order: 11;
  }
}
.order-sm-12 {
  @include sm-max {
    order: 12;
  }
}

.order-md-0 {
  @include md-max {
    order: 0;
  }
}
.order-md-1 {
  @include md-max {
    order: 1;
  }
}
.order-md-2 {
  @include md-max {
    order: 2;
  }
}
.order-md-3 {
  @include md-max {
    order: 3;
  }
}
.order-md-4 {
  @include md-max {
    order: 4;
  }
}
.order-md-5 {
  @include md-max {
    order: 5;
  }
}
.order-md-6 {
  @include md-max {
    order: 6;
  }
}
.order-md-7 {
  @include md-max {
    order: 7;
  }
}
.order-md-8 {
  @include md-max {
    order: 8;
  }
}
.order-md-9 {
  @include md-max {
    order: 9;
  }
}
.order-md-10 {
  @include md-max {
    order: 10;
  }
}
.order-md-11 {
  @include md-max {
    order: 11;
  }
}
.order-md-12 {
  @include md-max {
    order: 12;
  }
}

.order-lg-0 {
  @include lg-max {
    order: 0;
  }
}
.order-lg-1 {
  @include lg-max {
    order: 1;
  }
}
.order-lg-2 {
  @include lg-max {
    order: 2;
  }
}
.order-lg-3 {
  @include lg-max {
    order: 3;
  }
}
.order-lg-4 {
  @include lg-max {
    order: 4;
  }
}
.order-lg-5 {
  @include lg-max {
    order: 5;
  }
}
.order-lg-6 {
  @include lg-max {
    order: 6;
  }
}
.order-lg-7 {
  @include lg-max {
    order: 7;
  }
}
.order-lg-8 {
  @include lg-max {
    order: 8;
  }
}
.order-lg-9 {
  @include lg-max {
    order: 9;
  }
}
.order-lg-10 {
  @include lg-max {
    order: 10;
  }
}
.order-lg-11 {
  @include lg-max {
    order: 11;
  }
}
.order-lg-12 {
  @include lg-max {
    order: 12;
  }
}

.order-xl-0 {
  @include xl-max {
    order: 0;
  }
}
.order-xl-1 {
  @include xl-max {
    order: 1;
  }
}
.order-xl-2 {
  @include xl-max {
    order: 2;
  }
}
.order-xl-3 {
  @include xl-max {
    order: 3;
  }
}
.order-xl-4 {
  @include xl-max {
    order: 4;
  }
}
.order-xl-5 {
  @include xl-max {
    order: 5;
  }
}
.order-xl-6 {
  @include xl-max {
    order: 6;
  }
}
.order-xl-7 {
  @include xl-max {
    order: 7;
  }
}
.order-xl-8 {
  @include xl-max {
    order: 8;
  }
}
.order-xl-9 {
  @include xl-max {
    order: 9;
  }
}
.order-xl-10 {
  @include xl-max {
    order: 10;
  }
}
.order-xl-11 {
  @include xl-max {
    order: 11;
  }
}
.order-xl-12 {
  @include xl-max {
    order: 12;
  }
}
