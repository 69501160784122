.main {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  @include md-max {
    flex-direction: row;
  }

  @include lg-max {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

.left-block {
  display: block;
  width: 100%;
  height: auto;
  background-color: #fff;

  @include md-max {
    width: 50%;
    height: 100%;
  }
}

.right-block {
  display: block;
  width: 100%;
  height: auto;
  background-image: url('/build/images/index/main-bg.jpg');
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;


  @include md-max {
    width: 50%;
    height: 100%;
  }

  &__title {
    order: -2;
    width: 100%;
    max-width: 320px;
    font-weight: 900;
    font-size: 28px;
    line-height: 35px;
    margin: 15px auto 55px auto;
    text-transform: uppercase;
    color: #6959A1;

    @include md-max {
      order: -1;
      margin-bottom: 180px;
      max-width: 485px;
      font-size: 36px;
      line-height: 48px;
    }

    @include lg-max {
      font-size: 45px;
      line-height: 56px;
      margin-bottom: 185px;
    }

    @include xl-max {
      margin: 0 auto 188px 0;
    }

    @media (min-width: 992px) and (min-height: 500px) {
      font-size: 36px;
      line-height: 45px;
      margin-bottom: 50px;
    }

    @media (min-width: 992px) and (min-height: 950px) {
      font-size: 45px;
      line-height: 56px;
      margin-bottom: 185px;
    }

    @media (min-width: 1200px) and (min-height: 950px) {
      margin: 0 auto 188px 0;
    }
  }
}

.content-left {
  width: 100%;
  padding: 15px;

  @include md-max {
    max-width: 563px;
    padding: 0;
    margin: 50px 30px 60px auto;
  }

  @include lg-max {
    padding-left: 15px;
  }

  @include xl-max {
    padding-left: 0;
  }

  @media (min-width: 992px) and (min-height: 500px) {
    margin: 25px 30px 60px auto;
  }

  @media (min-width: 992px) and (min-height: 950px) {
    margin: 50px 30px 60px auto;
  }
}

.content-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  padding-bottom: 50px;

  @include md-max {
    padding: 0 0 0 30px;
    margin: 50px auto 0 0;
    max-width: 580px;
  }

  @include lg-max {
    padding: 0;
    margin: 50px auto 0 30px;
  }

  @include xl-max {
    margin: 50px auto 0 95px;
  }

  @media (min-width: 992px) and (min-height: 500px) {
    margin: 25px auto 0 30px;
  }

  @media (min-width: 1140px) and (min-height: 950px) {
    margin: 50px auto 0 95px;
  }
}

.header-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 22px;

  @include md-max {
    margin-bottom: 42px;
  }
}

.logo {
  width: 100%;
  max-width: 194px;

  @include md-max {
    max-width: 280px;
  }

  @include lg-max {
    max-width: 379px;
  }

  @media (min-width: 992px) and (min-height: 500px) {
    max-width: 285px;
  }

  @media (min-width: 992px) and (min-height: 950px) {
    max-width: 379px;
  }
}

.link {
  &-tel {
    display: block;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    color: #fff;
    margin-bottom: 9px;
    text-align: center;

    @include md-max {
      display: inline-block;
      font-size: 18px;
      line-height: 29px;
      margin-bottom: 0;
      margin-right: 26px;
    }

    @include lg-max {
      font-size: 24px;
      margin-right: 40px;
    }

    &:hover {
      color: #fff;
    }

    &-icon {
      display: inline-block;
      width: 42px;
      height: 42px;
      background-image: url('/build/images/index/icon-phone.svg');
      -webkit-background-size: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;

      @include md-max {
        display: none;
      }
    }
  }
}

.title {
  width: 100%;
  font-weight: 900;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 14px;
  text-transform: uppercase;
  color: #6959A1;

  @include md-max {
    max-width: 520px;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 51px;
    padding-left: 15px;
  }

  @include lg-max {
    font-size: 45px;
    line-height: 56px;
    padding-left: 0;
  }

  @media (min-width: 992px) and (min-height: 500px) {
    font-size: 36px;
    line-height: 45px;
  }

  @media (min-width: 992px) and (min-height: 950px) {
    font-size: 45px;
    line-height: 56px;
  }

  &_white {
    color: #fff;
  }
}

.desc {
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #4D4D4D;
  margin-bottom: 27px;

  @include md-max {
    font-size: 22px;
    line-height: 130%;
    margin-bottom: 61px;
    padding-left: 15px;
  }

  @include lg-max {
    font-size: 28px;
    line-height: 140%;
    padding-left: 0;
  }

  @media (min-width: 992px) and (min-height: 500px) {
    font-size: 22px;
    margin-bottom: 15px;
  }

  @media (min-width: 992px) and (min-height: 950px) {
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 61px;
  }
  @media (min-width: 1140px) and (min-height: 950px) {
    font-size: 28px;
    line-height: 140%;
  }
}

.btn-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 74px;
  align-items: center;

  @include sm-max {
    flex-direction: row;
    align-items: center;
    margin-bottom: 35px;
  }

  @include md-max {
    padding: 0 15px;
  }

  @include lg-max {
    padding: 0;
  }

}

.btn {

  &-orange {
    display: inline-block;
    width: 100%;
    max-width: 280px;
    padding: 20px 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff;
    background: linear-gradient(79.04deg, #F97700 0%, #FA9600 100%);
    box-shadow: 0px 0px 25px rgba(250, 146, 0, 0.25);
    border-radius: 3px;
    border: none;
    margin-bottom: 21px;
    text-align: center;
    transition: all .5s;

    @include sm-max {
      margin-bottom: 0;
      margin-right: 15px;
    }

    @include md-max {
      margin-bottom: 0;
      margin-right: 19px;
      max-width: 223px;
      font-size: 13px;
    }

    @include lg-max {
      font-size: 15px;
    }

    &:hover {
      border: none;
      background: linear-gradient(79.04deg, #E25100 0%, #FA9600 100%);
      box-shadow: 0px 0px 25px rgba(250, 146, 0, 0.3);
      color: #fff;
    }
  }

  &-outline-orange {
    display: inline-block;
    width: 100%;
    max-width: 280px;
    padding: 20px 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #F97700;
    background: transparent;
    border: 2px solid #FA9600;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 25px rgba(250, 146, 0, 0.25));
    border-radius: 3px;
    text-align: center;
    transition: all .5s;

    @include md-max {
      max-width: 223px;
      font-size: 13px;
    }

    @include lg-max {
      font-size: 15px;
    }

    &:hover {
      background: linear-gradient(79.04deg, #E25100 0%, #FA9600 100%);
      box-shadow: 0px 0px 25px rgba(250, 146, 0, 0.3);
      color: #fff;

      & .icon-btn1 {
        background-image: url('/build/images/index/icon-btn1-hover.svg')
      }

      & .icon-btn2 {
        background-image: url('/build/images/index/icon-btn2-hover.svg')
      }

      & .icon-btn3 {
        background-image: url('/build/images/index/icon-btn3-hover.svg')
      }
    }

    &__icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin-right: 20px;
      background-repeat: no-repeat;
      -webkit-background-size: 100%;
      background-size: 100%;
      background-position: center;
    }
  }
}

.gallery-container {
  position: relative;
  display: block;

  @include md-max {
    height: 213px;
  }
}

.gallery-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 64px;
  list-style-type: none;

  @include md-max {
    flex-direction: row;
    position: absolute;
    top: 40px;
    left: 0;
    margin-bottom: 0;
  }

  @include lg-max {
    top: 0;
  }
}

.gallery-item {
  display: inline-block;
  width: 280px;
  max-width: 280px;
  min-width: 280px;
  height: 170px;
  margin-bottom: 15px;
  background: #6959A1;
  box-shadow: 0px 5px 15px rgba(43, 28, 96, 0.5);
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;

  @include sm-max {
    width: 360px;
    max-width: 360px;
    min-width: 360px;
    height: 170px;
    margin-bottom: 25px;
  }

  @include md-max {
    width: 228px;
    max-width: 228px;
    min-width: 228px;
    height: 148px;
    margin-bottom: 0;
    margin-right: 36px;
  }

  @include lg-max {
    width: 348px;
    max-width: 348px;
    min-width: 348px;
    height: 213px;
  }

  @media (min-width: 992px) and (min-height: 500px) {
    width: 228px;
    max-width: 228px;
    min-width: 228px;
    height: 148px;
  }

  @media (min-width: 1140px) and (min-height: 950px) {
    width: 348px;
    max-width: 348px;
    min-width: 348px;
    height: 213px;
  }
}

.link-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  order: -1;
  margin-bottom: 34px;

  @include md-max {
    flex-direction: row;
    height: 94px;
    order: -2;
    margin-bottom: 16px;
  }

  @include lg-max {
    height: 113px;
    margin-bottom: 25px;
  }

  @include xl-max {
    margin-bottom: 42px;
  }

  @media (min-width: 992px) and (min-height: 500px) {
    height: 86px;
    margin-bottom: 42px;
  }

  @media (min-width: 992px) and (min-height: 950px) {
    height: 113px;
    margin-bottom: 25px;
  }

  @media (min-width: 1200px) and (min-height: 950px) {
    margin-bottom: 42px;
  }
}

.link-mail {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  margin-bottom: 27px;

  @include md-max {
    justify-content: flex-start;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 39px;
  }

  @include lg-max {
    font-size: 30px;
  }

  &:hover {
    color: #fff;
  }
}

.icon-mail {
  display: inline-block;
  width: 33px;
  height: 22px;
  margin-right: 16px;
  background-image: url('/build/images/index/icon-mail.svg');
  background-repeat: no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-position: center;

  @include md-max {
    width: 38px;
    height: 27px;
    margin-right: 18px;
  }

  @include lg-max {
    width: 40px;
  }
}

.link-social-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  @include md-max {
    justify-content: flex-start;
    margin-bottom: 0;
  }
}

.link-social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 9px;

  @include md-max {
    width: 45px;
    height: 45px;
    margin-right: 11px;
  }

  @include lg-max {
    width: 56px;
    height: 56px;
  }
}

.link-social-icon {
  display: inline-block;
  width: 27px;
  height: 27px;
  background-repeat: no-repeat;
  -webkit-background-size: auto;
  background-size: auto;
  background-position: center;
}

.classic-modal {
  display: block;
  width: 100%;
  min-width: 272px;
  max-width: 538px;
  min-height: 390px;
  background-color: #fff;
  border-radius: 4px;

  @include md-max {
    width: 538px;
    min-width: 538px;
    max-width: 538px;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 89px;
    background: #DBCEFF;
    border-radius: 4px 4px 0px 0px;
  }

  &__logo {
    width: 100%;
    max-width: 190px;

    @include md-max {
      max-width: 255px;
    }
  }

  &__close {
    position: absolute;
    top: 14px;
    right: 21px;
    cursor: pointer;
  }

  &__body {
    padding: 30px 15px;

    @include md-max {
      padding: 40px 37px;
    }
  }

  &__title {
    display: block;
    max-width: 350px;
    margin: 0 auto 40px auto;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: #4D4D4D;

    @include md-max {
      font-size: 28px;
    }
  }

  &__btn {
    margin: 6px auto;

    &-group {
      &-row {
        display: flex;
        flex-direction: column;

        @include md-max {
          flex-direction: row;
        }
      }
      &-column {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-column {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 348px!important;
      margin-bottom: 15px;
      padding: 6px 38px;

      @include md-max {
        padding: 10px 90px;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }

  &__input {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(77, 77, 77, 0.8);
    border: 1px solid #4D4D4D;
    box-sizing: border-box;
    border-radius: 3px;
  }

  &__submit {
    max-width: none!important;
  }

  &__desc {
    display: block;
    margin: 15px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #6959A1;

    & a {
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #6959A1;
    }
  }
}

.icon-btn1 {
  background-image: url('/build/images/index/icon-btn1.svg')
}

.icon-btn2 {
  background-image: url('/build/images/index/icon-btn2.svg')
}

.icon-btn3 {
  background-image: url('/build/images/index/icon-btn3.svg')
}

.message-error {
  display: block;
  padding: 15px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.55;
  color: #f95d51;
  text-align: center;
}

.success-message {
  display: none;
  padding: 15px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.55;
  color: #fff;
  background-color: #62c584;
  text-align: center;
}

.input-error {
  display: none;
  margin-top: -10px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.55;
  color: #f95d51;
}

.loading_button {
  background-image: url('/build/images/index/loading_form.gif');
  background-repeat: no-repeat!important;
  background-position: 50%!important;
  background-size: 35px!important;
  background-color: #F97700;
  opacity: .7;

  &:hover {
    background-image: url('/build/images/index/loading_form.gif');
    background-repeat: no-repeat!important;
    background-position: 50%!important;
    background-size: 35px!important;
    background-color: #F97700;
    opacity: .7;
  }
}

.btn-join-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 223px;
  height: 54px;
  background: linear-gradient(79.04deg, #F97700 0%, #FA9600 100%);
  box-shadow: 0px 0px 25px rgba(250, 146, 0, 0.25);
  border-radius: 3px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
  margin: 6px auto;
  transition: all .3s;

  @include md-max {
    margin: 0 19px 0 0;
  }

  &:hover {
    background: linear-gradient(79.04deg, #E25100 0%, #FA9600 100%);
    box-shadow: 0px 0px 25px rgba(250, 146, 0, 0.3);
    color: #fff;
  }
}

.btn-outline-join-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 223px;
  height: 54px;
  background: transparent;
  border: 1px solid #F97700;
  border-radius: 3px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #F97700;
  transition: all .3s;
  margin: 6px auto;

  @include md-max {
    margin: 0;
  }

  &:hover {
    background: linear-gradient(79.04deg, #E25100 0%, #FA9600 100%);
    box-shadow: 0px 0px 25px rgba(250, 146, 0, 0.3);
    color: #fff;
  }
}

.language {
  display: none;

  @include md-max {
    display: flex;
  }
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;

  & a {
    margin-left: 10px;
    color: #fff;
    
    &:hover {
      color: #eee;
    }
  }
}

.language-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
  margin: -15px 0 15px 0;
  text-transform: uppercase;

  @include md-max {
    display: none;
  }

  & a {
    margin-right: 10px;
    color: #F97700;

    &:hover {
      color: #000;
    }
  }
}